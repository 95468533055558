export default [
  {
    text: "Я обучаюсь в настоящий момент",
    value: false,
  },
  {
    text: "Я не являюсь студентом в данный момент",
    value: true,
  },
];
