export default [
  {
    text: "Я был студентом ВУЗа",
    value: 1,
  },
  {
    text: "Я был студентом СПО",
    value: 2,
  },
];
