<template>
  <div class="hs-profile" v-if="user.first_name && user.last_name">
    <div class="hs-profile__body">
      <div class="hs-profile__left">
        <Input
          placeholder="Введите свою фамилию"
          header="Фамилия"
          info-text="В случае смены фамилии, необходимо внести в данное поле актуальные данные"
          v-model="formData.last_name"
          :disabled="isDisabled"
        />
        <Input
          header="Имя"
          placeholder="Введите свое имя"
          v-model="formData.first_name"
          canEdit="profile"
          editFieldName="first_name"
          :disabled="isDisabled"
        />
        <Input
          header="Отчество (при наличии)"
          placeholder="Введите свое отчество"
          v-model="formData.middle_name"
          canEdit="profile"
          editFieldName="middle_name"
          :disabled="isDisabled"
        />
        <Input
          header="Дата рождения"
          placeholder="01.10.2000"
          type="date"
          v-model="formData.birthday"
          :error="v$.formData.birthday.$errors.length !== 0"
          :autocomplete="false"
          @mousedown="formData.birthday = ''"
          :disabled="isDisabled"
        />
        <Input
          :placeholder="user.email"
          disabled
          header="Электронная почта"
          readonly
        />
        <Input
          header="Номер телефона"
          placeholder="+7 (917) 000-00-00"
          type="tel"
          :mask="'+7(###)-###-##-##'"
          v-model="formData.phone"
          :error="v$.formData.phone.$errors.length !== 0"
          canEdit="profile"
          editFieldName="phone"
          :disabled="isDisabled"
        />
        <Input
          header="Ссылка на страницу в ВКонтакте"
          placeholder="https://vk.com/"
          v-model="socials.vk.href"
          :error="emptySocials"
          :disabled="isDisabled"
        />
        <Input
          header="Ссылка на публикацию на личной странице в социальной сети Вконтакте публикацию об участии в Конкурсе с хэштегами #жильемолодым, #силалиги"
          placeholder="https://vk.com/"
          v-model="formData.href_vk_publication_project"
          :error="emptyLink"
          :disabled="isDisabled"
        />
      </div>
      <div class="hs-profile__right">
        <div class="hs-profile__radio">
          <TabsRadio
            :tabData="tabsRadioIsStudent"
            v-model="is_not_student"
            :error="v$.is_not_student.$errors.length !== 0"
            :disabled="isDisabled"
            @update:modelValue="
              () => {
                this.formData = {};
                this.educational_establishment_name = '';
                this.faculty_name = '';
                this.vuz_type = '';
              }
            "
          />
        </div>
        <div class="hs-profile__radio">
          <TabsRadio
            :tabData="
              is_not_student ? tabsRadioPastEduction : tabsRadioEducation
            "
            v-model="vuz_type"
            :disabled="isDisabled"
            @update:modelValue="
              () => {
                Object.keys(this.formData).forEach(
                  (item) => (this.formData[item] = '')
                );
                this.educational_establishment_name = '';
                this.faculty_name = '';
                if (this.vuz_type === 2) {
                  this.formData.level_education = 1;
                }
                if (user.info_educational_establishment.type.key === vuz_type) {
                  loadInfo();
                }
              }
            "
          />
        </div>
        <div class="hs-profile__form"></div>
        <template v-if="vuz_type">
          <InputSearch
            header="Учебное заведение"
            placeholder="Начните вводить аббревиатуру или наименование"
            :results-array="filteredUniversitiesList"
            @form-item="setIdUniversity"
            v-model="educational_establishment_name"
            :disabled="isDisabled"
          />
          <InputSearch
            v-if="facultiesList?.length > 1 || facultiesList?.length === 0"
            header="Факультет/институт"
            placeholder="Выберите из списка"
            :results-array="facultiesList"
            @form-item="setIdFaculty"
            v-model="faculty_name"
            :error="v$.formData.faculty_id.$errors.length !== 0"
            :key="universitiesList"
            :disabled="
              isDisabled ||
              facultiesList?.length === 0 ||
              !formData.educational_establishment_id
            "
            :readonly="
              facultiesList?.length === 0 ||
              !formData.educational_establishment_id
            "
          />
          <Input
            header="Специальность"
            placeholder="Введите свою специальность"
            v-model="formData.speciality_education"
            :error="v$.formData.speciality_education.$errors.length !== 0"
            :disabled="isDisabled"
          />
          <Dropdown
            :disabled="isDisabled"
            header="Уровень образования"
            placeholder="Выберите из списка"
            v-model="formData.level_education"
            :keys="
              educationLevelList.map((item) => {
                return item.translate;
              })
            "
            :items="
              educationLevelList.map((item) => {
                return item.id;
              })
            "
            :error="v$.formData.level_education.$errors.length !== 0"
            @update:modelValue="
              () => {
                this.levelEducationChange();
              }
            "
          />
          <Dropdown
            header="Курс"
            placeholder="Выберите из списка"
            :items="currentCoursesList"
            :keys="currentCoursesList"
            v-model="formData.course_level"
            :disabled="isDisabled || !formData.level_education"
            :error="v$.formData.course_level.$errors.length !== 0"
          />
          <Input
            header="Группа"
            placeholder="Введите данные"
            v-model="formData.group"
            :error="v$.formData.group.$errors.length !== 0"
            :disabled="isDisabled"
            v-if="
              this.formData.level_education !== 15 &&
              this.formData.level_education !== 18
            "
          />
          <Dropdown
            header="Год окончания учебного заведения"
            :items="is_not_student ? yearEndingNotStudent : yearEnding"
            :keys="is_not_student ? yearEndingNotStudent : yearEnding"
            placeholder="Выберите из списка"
            v-model="formData.year_ending_education"
            last
            :disabled="isDisabled"
          />
        </template>
      </div>
    </div>
    <div class="hs-profile__btn" v-if="!isDisabled">
      <Button @click="saveInfo" :loading="isBtnLoading">
        Сохранить изменения
      </Button>
      <Button secondary v-if="false">Сохранить как черновик</Button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Input from "@/components/Blocks/Input";
import InputSearch from "@/components/Blocks/InputSearch";
import moment from "moment";
import Dropdown from "@/components/Blocks/Dropdown";
import TabsRadio from "@/components/Blocks/TabsRadio";
import tabsRadioEducation from "@/enums/tabsRadioEducation";
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { setDisabledField } from "@/utils/helpers";
import validations from "@/enums/validations";
import useVuelidate from "@vuelidate/core";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import tabsRadioIsStudent from "@/enums/tabsRadioIsStudent";
import tabsRadioPastEduction from "@/enums/tabsRadioPastEduction";
import { helpers, required } from "@vuelidate/validators";

export default {
  name: "HousingStockProfile",
  components: { Button, TabsRadio, Dropdown, InputSearch, Input },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isBtnLoading: false,
      tabsRadioEducation,
      tabsRadioPastEduction,
      tabsRadioIsStudent,
      educational_establishment_name: "",
      faculty_name: "",
      vuz_type: "",
      is_not_student: "",
      formData: {
        last_name: "",
        first_name: "",
        middle_name: "",
        birthday: "",
        phone: "",
        educational_establishment_id: "",
        year_ending_education: "",
        href_vk_publication_project: "",
        faculty_id: "",
        speciality_education: "",
        group: "",
        level_education: "",
        course_level: "",
      },
      socials: {
        vk: {
          href: "",
          type: "vk",
        },
      },
      emptySocials: false,
      emptyLink: false,
    };
  },
  props: ["currentProject"],
  emits: ["update:currentProject"],
  watch: {
    user() {
      this._init();
    },
  },
  validations() {
    if (
      this.formData.level_education === 15 ||
      this.formData.level_education === 18
    ) {
      validations.housingStockProfile.group = {};
    } else
      validations.housingStockProfile.group.required = helpers.withMessage(
        "Обязательно к заполнению",
        required
      );
    return {
      formData: validations.housingStockProfile,
      vuz_type: validations.vuz_type,
      is_not_student: validations.is_not_student,
    };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.housingStockProfile;
    },
    universitiesList() {
      let array = JSON.parse(
        JSON.stringify(this.storage.systemInfo.educational_establishments)
      );
      return array.filter((item) => {
        return this.vuz_type === item.type.key;
      });
    },
    filteredUniversitiesList() {
      return this.universitiesList
        .filter((university) => {
          return university?.city_id === this.user.education_city_id;
        })
        .sort((a, b) => {
          let fieldA = a?.title_full?.toLowerCase();
          let fieldB = b?.title_full?.toLowerCase();

          if (fieldA < fieldB) return -1;

          if (fieldA > fieldB) return 1;

          return 0;
        });
    },
    facultiesList() {
      let array = JSON.parse(JSON.stringify(this.universitiesList));
      let item = array.find((item) => {
        return item.id === this.formData.educational_establishment_id;
      });
      return item?.faculties;
    },
    educationLevelList() {
      let sortedArr = JSON.parse(
        JSON.stringify(this.storage.systemInfo.level_education)
      );

      Object.values(sortedArr).forEach((item, index) => {
        item.id = +Object.keys(sortedArr)[index];

        return item;
      });
      if (this.vuz_type === 2) {
        return Object.values(sortedArr).filter((item) => {
          return item.id === 1;
        });
      } else {
        return Object.values(sortedArr).filter((i) => {
          return i.id !== 1;
        });
      }
    },
    currentCoursesList() {
      return this.educationLevelList.find((item) => {
        return item.id === this.formData.level_education;
      })?.settings?.course_list;
    },
    yearEnding() {
      return Array.from(Array(7).keys()).map((item) => {
        return (item += moment().year());
      });
    },
    yearEndingNotStudent() {
      return Array.from(Array(16).keys())
        .map((item) => {
          return (item = moment().subtract(item, "year").year());
        })
        .sort((a, b) => {
          return a - b;
        });
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    ...mapState("user", ["user"]),
    ...mapState([
      "storage",
      "currentCategoryData",
      "statementId",
      "currentCategoryId",
      "isOldApplication",
    ]),
  },
  methods: {
    async setFakeUserData() {
      Object.keys(this.formData).forEach((item) => {
        this.user[item] = this.formData[item];
      });
      if (
        this.user.socials.data.length > 0 &&
        this.user.socials.data.some((item) => {
          return item.type === "vk";
        })
      ) {
        Object.assign(
          this.user.socials.data.find((i) => {
            return i.type === "vk";
          }),
          this.socials.vk
        );
      } else {
        this.user.socials.data.push(this.socials.vk);
      }

      if (!this.user.info_educational_establishment?.id)
        this.user.info_educational_establishment = {
          id: null,
          title_full: "",
          type: { vuz_type: "" },
        };

      this.user.info_educational_establishment.id =
        this.formData.educational_establishment_id;
      this.user.info_educational_establishment.title_full =
        this.educational_establishment_name;
      this.user.info_educational_establishment.type.key = this.vuz_type;

      this.user.info_educational_establishment?.type?.key === 2
        ? this.$store.commit("user/setIsSPO", true)
        : this.$store.commit("user/setIsSPO", false);
      this.user.info_faculty = {
        id: this.formData.faculty_id,
        title_full: this.faculty_name,
      };
      this.user.info_level_education.id = this.formData.level_education;
      this.user.info_course_level.id = this.formData.course_level;
      this.user.is_not_student = this.is_not_student;
    },
    async _init() {
      await this.loadInfo();
      this.vuz_type =
        this.currentCategoryData?.info_educational_establishment?.type?.key ??
        this.user?.info_educational_establishment?.type?.key;
      this.is_not_student = this.user.is_not_student;
    },
    loadInfo() {
      Object.keys(this.formData).forEach((item) => {
        return (this.formData[item] =
          this.currentCategoryData[item] ?? this.user[item]);
      });

      this.formData.birthday = moment(this.user.birthday).format("YYYY-MM-DD");
      this.educational_establishment_name =
        this.currentCategoryData?.info_educational_establishment?.title_full ??
        this.user?.info_educational_establishment?.title_full;
      this.formData.educational_establishment_id =
        this.currentCategoryData?.info_educational_establishment?.id ??
        this.user?.info_educational_establishment?.id;
      this.faculty_name =
        this.currentCategoryData?.info_faculty?.title_full ??
        this.user?.info_faculty?.title_full;
      this.formData.faculty_id =
        this.currentCategoryData?.info_faculty?.id ??
        this.user?.info_faculty?.id;
      this.formData.course_level =
        this.user.currentCategoryData?.id ?? this.user.info_course_level?.id;
      this.formData.level_education =
        this.currentCategoryData.info_level_education?.id ??
        this.user.info_level_education?.id;
      this.socials.vk.href = this.user.socials?.data?.find((item) => {
        return item.type === "vk";
      })?.href;
    },
    setIdUniversity(item) {
      this.formData.educational_establishment_id = item.id;
      this.formData.faculty_id = "";
      this.faculty_name = "";
      if (this.facultiesList && this.facultiesList.length === 1) {
        this.formData.faculty_id = this.facultiesList[0].id;
        this.faculty_name = this.facultiesList[0].title_full;
      }
      if (this.facultiesList && this.facultiesList.length === 0) {
        this.formData.faculty_id = "";
        this.faculty_name = "";
      }
    },
    setIdFaculty(item) {
      this.formData.faculty_id = item.id;
    },
    async saveInfo() {
      if (!this.socials.vk.href) {
        this.emptySocials = true;
        this.$store.commit("pushToTray", {
          text: "Ссылка на страницу Вконтакте: Необходима ссылка на страницу Вконтакте",
          type: "error",
        });
        return;
      }
      if (!this.socials.vk.href?.includes("https://vk.com")) {
        this.$store.commit("pushToTray", {
          text: "Ссылка на страницу Вконтакте: Ссылка должна быть в формате: https://vk.com/",
          type: "error",
        });
        this.emptySocials = true;
        return;
      }
      if (
        !this.formData.href_vk_publication_project?.includes("https://vk.com")
      ) {
        this.$store.commit("pushToTray", {
          text: "Ссылка на публикацию: Ссылка должна быть в формате: https://vk.com/",
          type: "error",
        });
        this.emptyLink = true;
        return;
      }
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      this.isBtnLoading = true;

      let payloadObject = {};

      Object.keys(this.formData).map((item) => {
        if (this.formData[item]) {
          return (payloadObject[item] = this.formData[item]);
        }
      });
      payloadObject.birthday = `${this.formData.birthday} 00:00:00`;
      if (this.socials.vk.href) {
        payloadObject[`socials[vk][href]`] = this.socials.vk.href;
        payloadObject[`socials[vk][type]`] = this.socials.vk.type;
      }
      payloadObject.is_not_student = this.is_not_student ? "1" : "0";

      createRequest(requestConfigs.POSTSaveInfo, {
        jsonPayload: payloadObject,
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          this.setFakeUserData().then(() => {
            this.$store.commit("user/setDisabledData", [
              "isNotStudent",
              this.is_not_student,
            ]);
            this.setDisabledField(
              ["first_name", "last_name", "phone"],
              "isHSProfileFilled",
              this.formData
            ).then(() => {
              this.$router.push({ name: "HousingStockSocialActivity" });
            });
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    levelEducationChange() {
      this.formData.course_level = "";
      if (
        this.formData.level_education === 15 ||
        this.formData.level_education === 18
      ) {
        this.formData.group = "";
      }
    },
    setDisabledField,
  },
  mounted() {
    if (this.user) {
      this._init();
    }
  },
};
</script>

<style lang="scss">
.hs-profile {
  &__body {
    display: flex;
    gap: 20px;
    margin-bottom: 16px;
    @include adaptive(tablet-big) {
      flex-wrap: wrap;
    }
  }
  &__left,
  &__right {
    flex: 0 1 728px;
  }
  &__radio {
    margin: 25px 0;
  }
  &__btn {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
}
</style>
